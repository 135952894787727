import React from 'react'
import VehicleCheckoutContent from '../components/vehicle-checkout-components/vehicle-checkout-components'
import Layout from '../layouts'

const VehicleCheckout = () => {
  return (
   <Layout>
    <VehicleCheckoutContent/>
   </Layout>
  )
}

export default VehicleCheckout